import styled from 'styled-components';

// TODO extract into component
export const TextColumn = styled.div`
  text-align: justify;
  @media all and (min-width: 769px) {
    p {
      column-count: 2;
      column-gap: 2rem;
    }
  }
`;

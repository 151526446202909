import React                   from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { graphql }             from 'gatsby'

import Wrapper    from '../../components/Wrapper';
import Carousel   from '../../components/Carousel';
import Heading    from '../../components/Heading';
import Spacer     from '../../components/Spacer';
import BrandsBand from '../../components/BrandsBand';

import * as S from './styles';

import { urlalizer } from '../../components/_helpers';
import DefaultLayout from '../../layouts/Default';

function BrandPageTemplate(props) {
  const html = props.data.brand.html;
  const meta = props.data.brand.frontmatter;
  return (
    <DefaultLayout location={props.location}>
      <Carousel>
        {meta.headerImages.map((image) => (
          <img src={urlalizer(image)} key={image} alt={''}/>
        ))}
      </Carousel>
      <Spacer/>
      <Wrapper>
        <Heading>
          <img src={urlalizer(meta.logoPath)} alt={meta.title}/>
        </Heading>
        <Container>
          <Row>
            <Col md={8}>
              <S.TextColumn dangerouslySetInnerHTML={{ __html: html }}/>
            </Col>
            <Col md={4}>
              <aside>
                <h3>{meta.title} Online</h3>
                <b>Website: </b><a href={meta.website} title={meta.title} target='_blank'
                                   rel='noopener noreferrer'>{meta.title}</a>
              </aside>
            </Col>
          </Row>
        </Container>
        {meta.galleryImages &&
        <div>
          <Spacer/>
          <Carousel>
            {meta.galleryImages.map((image) => (
              <img src={urlalizer(image)} key={image} alt={''}/>
            ))}
          </Carousel>
        </div>}
        {meta.youtubeVideo &&
        <div style={{ textAlign: 'center' }}>
          <Spacer/>
          <iframe width='560' height='315' src={`https://www.youtube-nocookie.com/embed/${meta.youtubeVideo}?rel=0&amp;controls=0`}
                  frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen title={`${meta.title} Video`}></iframe>
        </div>}
        <Spacer/>
        <h2 style={{ textAlign: 'center' }}>Unsere Trauring-Marken</h2>
        <BrandsBand brands={props.data.brands.edges}/>
        <Spacer/>
      </Wrapper>
    </DefaultLayout>
  )
}

export default BrandPageTemplate;

export const query = graphql`
  query BrandQuery($slug: String!) {
    brand: markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      id
      frontmatter {
        title
        slug
        website
        logoPath
        headerImages
        galleryImages
      }
      html
    }
    brands: allMarkdownRemark(filter: {frontmatter: {type: {eq: "brand"}}}, sort: {fields: [frontmatter___slug], order: ASC}) {
      edges {
        node {
          frontmatter {
            logoPath
            slug
            title
          }
        }
      }
    }
  }
`;